import * as React from 'react';
import {Helmet} from 'react-helmet';

import Layout from '@components/layouts/layout';
import Navigation from '@components/navigation/navigation';
import Footer from '@components/footer/footer';

import {Container, Row, Col} from 'react-bootstrap';
import PublishModule from '@components/publish/publish-module';

const KoloNaukoweSinglePage = ({pageContext}) => {
  const siteId = pageContext.siteId || 'agh-kn-dev';
  return (
    <Layout>
      <Helmet>
        <title>Publikacja witryny – Studenckie Koła Naukowe AGH</title>
        <meta name="robots" content="noindex"></meta>
        <meta property="og:title" content={`Publikacja witryny – Studenckie Koła Naukowe AGH`} />
      </Helmet>

      <Navigation customPagesNav={pageContext.customPagesNav} />

      <div className="publish py-xl">
        <Container>
          <Row>
            <Col md={6}>
              <h1>Publikacja</h1>
              <PublishModule password="sknagh" albusDumbledoreSiteId={siteId} buildTime={10} />
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </Layout>
  );
};

export default KoloNaukoweSinglePage;
