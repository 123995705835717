import * as React from 'react';

import {Button, Form, Alert} from 'react-bootstrap';

const PublishModule = ({password, albusDumbledoreSiteId, buildTime}) => {
  const url = "https://api.github.com/repos/skn-agh/website/dispatches";
  const token = process.env.GATSBY_GITHUB_TOKEN;
  const event_type = "deploy";

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [passwordState, setPasswordState] = React.useState(null);
  const [isAuthError, setIsAuthError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(null);

  if (!password || !albusDumbledoreSiteId) return null;

  const logIn = () => {
    if (passwordState === password) {
      setIsAuthError(false);
      setIsLoggedIn(true);
    } else {
      setIsAuthError(true);
    }
  };

  const handlePasswordChange = event => {
    setPasswordState(event.target.value);
  };

  const publishChanges = async () => {
    try {
      setIsLoading(true);
      setIsSuccess(null);

      await new Promise(resolve => setTimeout(resolve, 1500));

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          "Authorization": `token ${token}`,
          "Accept": "application/vnd.github.v3+json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_type: event_type,
        }),
      });

      if (response.ok)
        setIsSuccess(true);
      else 
        throw new Error('Publishing failed');
    } catch (error) {
      setIsSuccess(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getButtonText = () => {
    if (isLoading) return 'Proszę czekać';
    if (isSuccess === true) return 'Sukces';
    if (isSuccess === false) return 'Spróbuj ponownie';
    return 'Opublikuj';
  };

  return (
    <>
      {!isLoggedIn && (
        <div>
          <p>Wpisz hasło, by kontynuować</p>
          {isAuthError && <Alert variant="danger">Niepoprawne hasło</Alert>}
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Hasło</Form.Label>
              <Form.Control type="password" onChange={handlePasswordChange} />
            </Form.Group>
          </Form>
          <Button variant="dark" onClick={logIn}>
            Odblokuj
          </Button>
        </div>
      )}
      {isLoggedIn && (
        <div>
          <p>
            Wciśnij przycisk poniżej, aby wgrać nową wersję witryny (opublikować zmiany dokonane w panelu zarządzania
            Contentful CMS)
          </p>
          {isSuccess === false && (
            <Alert variant="danger">
              Niepowodzenie, spróbuj ponownie. Jeśli problem będzie się powtarzał, skontaktuj się z pomocą techniczną.
            </Alert>
          )}
          {isSuccess === true && (
            <Alert variant="success">
              Sukces. Poczekaj na publikację zmian (około {buildTime ? `${buildTime}` : `10`} min).
            </Alert>
          )}
          <Button variant="success" onClick={publishChanges} disabled={isLoading || isSuccess}>
            {getButtonText()}
          </Button>
        </div>
      )}
    </>
  );
};

export default PublishModule;
